.App {
  /* text-align: center; */
}

/* *{
  font-family: FuturaRoundDemi,FuturaRound;
} */

@font-face {
  font-family: futura;
  src: url(/src/assets/Fonts/FuturaRound.fb0e36af355393376035.ttf);
}

* {
  font-family: futura;
}

h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff !important;
  margin: 0px;
  padding: 0px;
}

h2 {
  padding-bottom: 20px;
  font-size: 24px;
}

p {
  font-size: 19px;
}

.btn-primary {
  background: #0081C3;
  text-transform: uppercase;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:991px) {
  h1 {
    font-size: 23px;
    text-align: center;
  }

  .section_heading h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .section_heading .mobile-font {
    margin-bottom: 0.9rem;
  }
}